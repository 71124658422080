import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ea59b3b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-checkbox" }
const _hoisted_2 = { class: "form-checkbox__label" }
const _hoisted_3 = ["checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _createElementVNode("input", {
        type: "checkbox",
        class: _normalizeClass([
          'form-checkbox__input',
          _ctx.error && 'form-checkbox__input--error',
        ]),
        onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
        checked: _ctx.checked
      }, null, 42, _hoisted_3),
      _createElementVNode("span", {
        class: _normalizeClass(['form-checkbox__text', _ctx.error && 'form-checkbox__text--error'])
      }, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ], 2)
    ])
  ]))
}