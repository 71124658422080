
import { defineComponent } from "vue";

export default defineComponent({
  name: "FormCheckbox",
  props: {
    modelValue: Boolean,
    checked: Boolean,
    error: Boolean,
  },
  emits: ["update:modelValue"],
  setup(_, { emit }) {
    const onChange = (event: Event) => {
      emit("update:modelValue", (event.target as HTMLInputElement).checked);
    };

    return {
      onChange,
    };
  },
});
