
import { defineComponent } from "vue";

export default defineComponent({
  name: "UploadProgressBar",
  props: {
    percentage: {
      required: true,
      type: Number,
    },
  },
});
