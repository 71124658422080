
import { defineComponent, getCurrentInstance, ref, watch } from "vue";
import { digitsFaToEn } from "@persian-tools/persian-tools";
import { DATE_REGEX } from "@/regex";

type Name = "year" | "month" | "day";

export default defineComponent({
  name: "FormDateInput",
  props: {
    modelValue: {
      required: false,
      type: String,
      default: null,
    },
    label: {
      required: true,
      type: String,
    },
    appendLabel: {
      required: false,
      type: String,
      default: null,
    },
    autofocus: Boolean,
    disabled: Boolean,
    error: Boolean,
    readonly: Boolean,
    errorMessage: {
      required: false,
      type: String,
      default: null,
    },
    hint: {
      required: false,
      type: String,
      default: null,
    },
    bgColor: {
      required: false,
      type: String,
      default: "#fff",
    },
    borderColor: {
      required: false,
      type: String,
      default: "#e0e0e0",
    },
    color: {
      required: false,
      type: String,
      default: "#afb7bd",
    },
  },
  setup(props, { emit }) {
    const instance = getCurrentInstance();
    const active = ref<boolean>(props.disabled ? false : props.autofocus);
    const yearInputRef = ref<HTMLInputElement>();
    const monthInputRef = ref<HTMLInputElement>();
    const dayInputRef = ref<HTMLInputElement>();
    const year = ref<string>();
    const month = ref<string>();
    const day = ref<string>();

    if (props.readonly || props.disabled) {
      active.value = false;
    }

    watch(
      () => props.modelValue,
      (v) => {
        if (v) {
          const reg = new RegExp(DATE_REGEX);

          if (reg.exec(v)) {
            const [y, m, d] = v.split("-");
            year.value = y;
            month.value = m;
            day.value = d;
          }
        }
      },
      {
        immediate: true,
      }
    );

    watch([year, month, day], (v) => {
      if (!v[0] && !v[1] && !v[2]) {
        emit("update:modelValue", "");
      }
      if (v[0] && v[1] && v[2]) {
        emit("update:modelValue", `${v[0]}-${v[1]}-${v[2]}`);
      }
    });

    const onInput = (e: InputEvent, name: Name) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const value: string = digitsFaToEn(e.target.value);

      if (name === "year") {
        year.value = value;
        if (value.length === 4) {
          monthInputRef.value?.focus();
        }
      } else if (name === "month") {
        month.value = value;
        if (value.length === 2) {
          dayInputRef.value?.focus();
        }
      } else if (name === "day") {
        day.value = value;
      }
    };

    const onKeydown = (e: KeyboardEvent) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const key = e.key;

      const keys = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "۰",
        "۱",
        "۲",
        "۳",
        "۴",
        "۵",
        "۶",
        "۷",
        "۸",
        "۹",
        "Backspace",
      ];

      !keys.includes(key) && e.preventDefault();
    };

    const onClick = () => {
      active.value = !props.disabled;

      // if all inputs are empty, focus on the year input
      if (
        (!year.value || year.value.length !== 4) &&
        !month.value &&
        !day.value
      ) {
        yearInputRef.value?.setSelectionRange(4, 4);
        yearInputRef.value?.focus();
      }

      if (
        year.value &&
        year.value.length === 4 &&
        (!month.value || month.value.length !== 2) &&
        !day.value
      ) {
        monthInputRef.value?.setSelectionRange(2, 2);
        monthInputRef.value?.focus();
      }

      if (
        month.value &&
        month.value.length === 2 &&
        (!day.value || day.value.length !== 2)
      ) {
        dayInputRef.value?.setSelectionRange(2, 2);
        dayInputRef.value?.focus();
      }

      if (
        year.value &&
        year.value.length === 4 &&
        month.value &&
        month.value.length === 2 &&
        (!day.value || day.value.length !== 2)
      ) {
        dayInputRef.value?.setSelectionRange(2, 2);
        dayInputRef.value?.focus();
      }

      if (
        year.value &&
        year.value.length === 4 &&
        month.value &&
        month.value.length === 2 &&
        day.value &&
        day.value.length === 2
      ) {
        dayInputRef.value?.setSelectionRange(2, 2);
        dayInputRef.value?.focus();
      }
    };

    const onFocus = (name: Name) => {
      if (name === "year") {
        yearInputRef.value?.select();
      } else if (name === "month") {
        monthInputRef.value?.select();
      } else if (name === "day") {
        dayInputRef.value?.select();
      }
    };

    const onFocusout = () => {
      if (!year.value && !month.value && !day.value) {
        active.value = false;
      }
    };

    const onDelete = (e: KeyboardEvent, name: Name) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const value: string = e.target.value;

      if (!value) {
        if (name === "month") {
          yearInputRef.value?.focus();
        } else if (name === "day") {
          monthInputRef.value?.focus();
        }
      }
    };

    return {
      active,
      id: `input-${instance?.uid}`,
      onInput,
      yearInputRef,
      monthInputRef,
      dayInputRef,
      onKeydown,
      year,
      month,
      day,
      onClick,
      onFocus,
      onDelete,
      onFocusout,
    };
  },
});
