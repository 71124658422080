import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-466b8a8a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "help" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_video_help_link = _resolveComponent("video-help-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "help__content",
      innerHTML: _ctx.account_limitation.content
    }, null, 8, _hoisted_2),
    _createVNode(_component_video_help_link, {
      title: "راهنمای ویدئویی تهیه‌ی این فایل",
      url: _ctx.account_limitation.video
    }, null, 8, ["url"]),
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close'))),
      class: "btn btn-primary w-100 block"
    }, " بازگشت به صفحه قبل ")
  ]))
}