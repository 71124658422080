import { reactive, toRefs } from "vue";
import useApi from "./useApi";
import useAuth from "./useAuth";
import { ChatDetailsResponse } from "@/types/chat";

const state = reactive({
  unreadCount: 0,
});

export default () => {
  const { call, response } = useApi<ChatDetailsResponse>();
  const { user } = useAuth();

  const fetchUnreadMessages = async () => {
    if (!user.value) return;

    await call({
      url: "/user/chat-details",
      method: "GET",
    });

    state.unreadCount = response.value?.unread_chat_count ?? 0;
  };

  setInterval(async () => {
    await fetchUnreadMessages();
  }, 10000);

  return {
    ...toRefs(state),
  };
};
