
import { defineComponent, ref, inject, watch } from "vue";
import Alert from "@/components/Ui/Alert.vue";
import { isMobileAgent } from "@/utils";
import { IsOpen } from "@/symbols";

export default defineComponent({
  name: "ResolvingLimitationStepOne",
  components: {
    Alert,
  },
  emits: ["record"],
  setup(_, { emit }) {
    const isCameraOpen = ref<boolean>(false);
    const cameraRef = ref<HTMLInputElement>();
    const inputFileRef = ref<HTMLInputElement>();
    const desktopCameraRef = ref<HTMLVideoElement>();
    let stream: MediaStream | undefined;
    let mediaRecorder: MediaRecorder;
    const isOpen = inject(IsOpen);
    const permissionError = ref<boolean>();
    let timerInterval: number;
    const timer = ref<number>(3);
    const chunks = ref<Blob[]>([]);

    const onCameraInputChange = async (e: Event) => {
      const target = e.target as HTMLInputElement;
      const file: File = (target.files as FileList)[0];

      emit("record", file);
    };

    const openCamera = async () => {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        try {
          stream = await navigator.mediaDevices.getUserMedia({
            video: {
              width: 210,
              height: 210,
            },
            audio: true,
          });
          isCameraOpen.value = true;

          timerInterval = setInterval(() => {
            if (timer.value > 0) {
              timer.value--;
            }

            if (timer.value === 0) {
              clearInterval(timerInterval);
              record();
            }
          }, 1500);
        } catch (error) {
          permissionError.value = true;
          stop();
        }

        if (desktopCameraRef.value && stream) {
          desktopCameraRef.value.srcObject = stream;
          desktopCameraRef.value.play();
        }
      } else {
        isCameraOpen.value = false;
      }
    };

    const record = () => {
      mediaRecorder = new MediaRecorder(stream as MediaStream, {
        mimeType: "video/webm",
      });

      chunks.value = [];

      mediaRecorder.ondataavailable = (e) => {
        chunks.value.push(e.data);
      };

      mediaRecorder.start(10);
    };

    const stopRecording = () => {
      const blob = new Blob(chunks.value, {
        type: "video/webm",
      });

      emit("record", blob);
      stop();
    };

    const stop = () => {
      if (desktopCameraRef.value) {
        desktopCameraRef.value.pause();
        desktopCameraRef.value.srcObject = null;
      }
      isCameraOpen.value = false;
      timer.value = 3;

      if (stream) {
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };

    // stop on modal close
    watch(
      () => isOpen?.value,
      (value) => {
        if (!value) {
          stop();

          clearInterval(timerInterval);
        }
      }
    );

    return {
      cameraRef,
      inputFileRef,
      isCameraOpen,
      onCameraInputChange,
      isMobileAgent,
      openCamera,
      desktopCameraRef,
      isOpen,
      permissionError,
      timer,
      stopRecording,
    };
  },
});
