import { reactive, toRefs } from "vue";
import { Chat, ChatListResponse } from "@/types/chat";
import useApi, { Status } from "./useApi";
import useBreakpoints from "./useBreakpoints";

interface StateType {
  page: number;
  messages: Chat[];
  errorMessage: string;
  firstPageMessages: Chat[];
}

const state = reactive<StateType>({
  page: 1,
  errorMessage: "",
  firstPageMessages: [],
  messages: [],
});

export default () => {
  const { call, response, status } = useApi<ChatListResponse>();
  const breakpoints = useBreakpoints();

  const fetch = async (page: number) => {
    await call({
      url: "/user/chat-list",
      method: "GET",
      params: {
        page,
      },
    });
  };

  const fetchMessages = async () => {
    await fetch(state.page);

    if (response.value && status.value === Status.SUCCESS) {
      if (state.page === 1) {
        state.messages.push(...response.value.items);
        state.firstPageMessages = response.value.items;
      } else {
        state.messages.unshift(...response.value.items);
      }
    }
  };

  const fetchLastMessages = async () => {
    await fetch(1);

    if (response.value && status.value === Status.SUCCESS) {
      if (
        JSON.stringify(response.value.items) !==
        JSON.stringify(state.firstPageMessages)
      ) {
        if (state.messages && state.messages.length) {
          response.value.items.forEach((chat) => {
            if (!state.messages.find((item) => item.id === chat.id)) {
              state.messages.push(chat);
            }
          });
        } else {
          state.messages = response.value.items;
        }

        if (breakpoints.isMobile) {
          (
            document.querySelector(".chat-message-box") as HTMLElement
          ).scrollIntoView();
        }
      }
    }
  };

  const onSendMessage = async (chat: Chat) => {
    state.messages.push(chat);
    await fetchLastMessages();
  };

  const onError = (msg: string) => {
    state.errorMessage = msg;
  };

  const nextPage = () => {
    state.page++;
  };

  const reset = () => {
    state.page = 1;
    state.messages = [];
    state.errorMessage = "";
    state.firstPageMessages = [];
  };

  return {
    ...toRefs(state),
    onSendMessage,
    onError,
    nextPage,
    response,
    status,
    fetchMessages,
    fetchLastMessages,
    reset,
  };
};
