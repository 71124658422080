
import { defineComponent, ref, computed, onMounted } from "vue";
import axios from "axios";
import Alert from "@/components/Ui/Alert.vue";
import UploadProgressBar from "@/components/Ui/UploadProgressBar.vue";
import useApi, { Status } from "@/composables/useApi";
import { UploadVideoResponse } from "@/types/auth";
import { ErrorType } from "@/types/base";
import { isMobileAgent } from "@/utils";

const CancelToken = axios.CancelToken;
export const source = CancelToken.source();

export default defineComponent({
  name: "ResolvingLimitationStepTwo",
  components: {
    Alert,
    UploadProgressBar,
  },
  props: {
    file: {
      type: [File, Blob],
      required: true,
    },
  },
  emits: ["back", "success"],
  setup(props, { emit }) {
    const percentage = ref<number>(0);
    const { call, status, error } = useApi<UploadVideoResponse>();
    const videoRef = ref<HTMLVideoElement>();
    const isVideoPlaying = ref<boolean>(false);
    const isVideoUploading = ref<boolean>(false);
    const errorMessage = ref<string>();
    const successAlert = ref<boolean>(false);

    const upload = async () => {
      const formData = new FormData();
      if (isMobileAgent()) {
        formData.append("VideoFile[file]", props.file);
      } else {
        const file = new File(
          [props.file],
          `video-${new Date().getTime()}.webm`,
          {
            type: props.file.type,
            lastModified: new Date().getTime(),
          }
        );
        formData.append("VideoFile[file]", file);
      }
      isVideoUploading.value = true;

      await call({
        url: "/user/upload-video",
        method: "POST",
        data: formData,
        onUploadProgress: function (progressEvent) {
          percentage.value = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
        cancelToken: source.token,
      });

      isVideoUploading.value = false;

      if (status.value === Status.SUCCESS) {
        successAlert.value = true;
        emit("success");
      }

      if (status.value === Status.ERROR) {
        const { errors } = error.value as unknown as ErrorType;
        errorMessage.value = errors.file[0];
      }
    };

    const play = () => {
      const video = videoRef.value;

      if (video) {
        video.play();
        isVideoPlaying.value = true;
      }
    };

    onMounted(() => {
      if (videoRef.value) {
        videoRef.value.onended = () => {
          isVideoPlaying.value = false;
        };
      }
    });

    return {
      videoRef,
      playerSrc: computed(() =>
        props.file ? URL.createObjectURL(props.file) : ""
      ),
      play,
      isVideoPlaying,
      upload,
      isVideoUploading,
      errorMessage,
      percentage,
      successAlert,
    };
  },
});
