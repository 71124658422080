
import { defineComponent, ref } from "vue";
import Modal from "@/components/Ui/Modal.vue";

export default defineComponent({
  name: "VideoHelpLink",
  components: {
    Modal,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "تماشا کنید.",
    },
  },
  setup(props) {
    const videoRef = ref<HTMLVideoElement>();
    const modal = ref<boolean>(false);

    const play = () => {
      modal.value = true;

      if (videoRef.value) {
        videoRef.value.src = props.url;
        videoRef.value.play();
      }
    };

    const onHide = () => {
      if (videoRef.value) {
        videoRef.value.pause();
        videoRef.value.currentTime = 0;
      }
    };

    return {
      modal,
      play,
      onHide,
    };
  },
});
