
import { defineComponent, ref } from "vue";
import ProgressBar from "@/components/Ui/ProgressBar.vue";
import { ChatSendResponse } from "@/types/chat";
import useConfig from "@/composables/useConfig";
import useApi, { Status } from "@/composables/useApi";
import useScrollToBottom from "@/composables/useScrollToBottom";
import useBreakpoints from "@/composables/useBreakpoints";

export default defineComponent({
  name: "ChatMessageBox",
  components: {
    ProgressBar,
  },
  emits: ["error", "send-message"],
  setup(_, { emit }) {
    const active = ref<boolean>(false);
    const message = ref<string>();
    const percentage = ref<number | null>(null);
    const fileInputRef = ref<HTMLInputElement>();
    const { chat_max_file_size } = useConfig();
    const breakpoints = useBreakpoints();

    const onInput = ({ value }: Partial<HTMLTextAreaElement>) => {
      if (value && value.length > 0) {
        if (!value?.trim()) {
          active.value = false;
        } else {
          active.value = true;
        }
      } else {
        active.value = false;
      }
    };

    const sendMessage = async () => {
      const { call, status, response } = useApi<ChatSendResponse>();

      await call({
        url: "/user/send-chat-msg",
        method: "POST",
        data: {
          msg: message.value,
        },
      });

      if (status.value === Status.SUCCESS) {
        message.value = "";
        emit("send-message", response.value?.item);
        active.value = false;

        setTimeout(() => {
          const selector = breakpoints.isMobile
            ? ".account-layout__content"
            : ".chat";
          useScrollToBottom(selector);
        }, 50);
      }
    };

    const uploadFile = async (file: File) => {
      const { call, status, response } = useApi<ChatSendResponse>();

      const formData = new FormData();
      formData.append("ChatFile[file]", file);
      active.value = false;

      await call({
        url: "/user/send-chat-file",
        method: "POST",
        data: formData,
        onUploadProgress: function (progressEvent) {
          percentage.value = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      });

      if (status.value === Status.SUCCESS) {
        percentage.value = null;
        emit("send-message", response.value?.item);
      }
    };

    const onFileInputChange = async (e: Event) => {
      const input = e.target as HTMLInputElement;

      if (input.files?.length) {
        const file = input.files[0];

        if (
          !["image/jpeg", "image/png", "application/pdf"].includes(file.type)
        ) {
          emit(
            "error",
            "آپلود فایل با فرمت jpg, jpeg, png, pdf امکان پذیر است"
          );
        } else if (file.size > chat_max_file_size.value * 1024 * 1024) {
          emit(
            "error",
            `آپلود فایل با حجم بیشتر از ${chat_max_file_size.value} مگابایت امکان پذیر نیست`
          );
        } else {
          await uploadFile(file);
        }
      }

      if (fileInputRef.value) {
        fileInputRef.value.type = "text";
        fileInputRef.value.type = "file";
      }
    };

    return {
      active,
      message,
      percentage,
      onInput,
      fileInputRef,
      onFileInputChange,
      sendMessage,
    };
  },
});
