
import { defineComponent, ref, provide, computed } from "vue";
import Modal from "@/components/Ui/Modal.vue";
import ResolvingLimitation from "@/components/Page/Account/Profile/ResolvingLimitation/ResolvingLimitation.vue";
import Help from "@/components/Page/Account/Profile/Help.vue";
import useConfig from "@/composables/useConfig";
import useAuth from "@/composables/useAuth";
import { IsOpen } from "@/symbols";

export default defineComponent({
  name: "ResolvingLimitationModal",
  components: {
    Modal,
    ResolvingLimitation,
    Help,
  },
  props: {
    modelValue: Boolean,
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const help = ref<boolean>(false);
    const { account_limitation } = useConfig();
    const { fetchUserDetails } = useAuth();

    provide(
      IsOpen,
      computed(() => props.modelValue)
    );

    const onDone = async () => {
      emit("update:modelValue", false);
      await fetchUserDetails();
    };

    return {
      help,
      account_limitation,
      onDone,
    };
  },
});
