
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProgressBar",
  props: {
    percentage: {
      required: true,
      type: Number,
    },
    label: {
      required: false,
      type: String,
      default: "در حال بارگذاری",
    },
  },
});
