
import { defineComponent, ref, onBeforeUnmount, computed } from "vue";
import { VariantTypes as Types } from "@/constants";

export default defineComponent({
  name: "Alert",
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: false,
      default: "",
    },
    variant: {
      type: String,
      required: false,
      validator: (val: string) => Types.includes(val),
      default: Types[0],
    },
    duration: {
      type: Number,
      default: 2,
      required: false,
    },
  },
  emits: ["close"],
  setup(props, { emit }) {
    const time = ref<number>(props.duration);

    let timeInterval: number;

    const setTimeInterval = () => {
      timeInterval = setInterval(() => {
        if (time.value > 0) {
          time.value -= 0.1;
        } else {
          emit("close");
        }
      }, 100);
    };

    setTimeInterval();

    onBeforeUnmount(() => {
      clearInterval(timeInterval);
    });

    return {
      time,
      percentage: computed(() => (time.value / props.duration) * 100),
    };
  },
});
