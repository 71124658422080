import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_resolving_limitation = _resolveComponent("resolving-limitation")!
  const _component_modal = _resolveComponent("modal")!
  const _component_help = _resolveComponent("help")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_modal, {
      "model-value": _ctx.modelValue,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_resolving_limitation, {
          onDone: _ctx.onDone,
          onHelp: _cache[0] || (_cache[0] = ($event: any) => {
        _ctx.$emit('update:modelValue', false);
        _ctx.help = true;
      })
        }, null, 8, ["onDone"])
      ]),
      _: 1
    }, 8, ["model-value"]),
    _createVNode(_component_modal, {
      modelValue: _ctx.help,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.help) = $event))
    }, {
      header: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.account_limitation.title), 1)
      ]),
      default: _withCtx(() => [
        _createVNode(_component_help, {
          onClose: _cache[2] || (_cache[2] = ($event: any) => {
        _ctx.help = false;
        _ctx.$emit('update:modelValue', true);
      })
        })
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}