
import { defineComponent } from "vue";
import useAuth from "@/composables/useAuth";

export default defineComponent({
  name: "Logout",
  setup() {
    const { logout } = useAuth();

    return {
      logout,
    };
  },
});
