
import { defineComponent, watch } from "vue";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import {
  verifyIranianNationalId,
  digitsFaToEn,
} from "@persian-tools/persian-tools";
import FormInput from "@/components/Ui/FormInput.vue";
import FormDateInput from "@/components/Ui/FormDateInput.vue";
import FormCheckbox from "@/components/Ui/FormCheckbox.vue";
import FormButton from "@/components/Ui/FormButton.vue";
import useAuth from "@/composables/useAuth";
import useApi, { Status } from "@/composables/useApi";
import { ErrorType } from "@/types/base";
import { DATE_REGEX, PERSIAN_REGEX } from "@/regex";

const validationSchema = yup.object({
  mobile: yup.string().required("شماره همراه را وارد کنید"),
  name: yup
    .string()
    .matches(PERSIAN_REGEX, { message: "فقط حروف فارسی مجاز است" })
    .required("نام را وارد کنید"),
  lastname: yup
    .string()
    .matches(PERSIAN_REGEX, { message: "فقط حروف فارسی مجاز است" })
    .required("نام خانوادگی را وارد کنید"),
  national_code: yup
    .string()
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    .test("national-code", "کد ملی نامعتبر است", (value) => {
      return verifyIranianNationalId(value);
    })
    .required("کدملی را وارد کنید"),
  birthday: yup
    .string()
    .matches(DATE_REGEX, { message: "تاریخ تولد نامعتبر است" })
    .required("تاریخ تولد را وارد کنید"),
});

export default defineComponent({
  name: "AccountActivationForm",
  components: {
    FormInput,
    FormDateInput,
    FormCheckbox,
    FormButton,
  },
  emits: ["submit", "failed"],
  setup(_, { emit }) {
    const { handleSubmit, meta, errors, setFieldValue } = useForm({
      validationSchema,
    });
    const { value: mobile } = useField("mobile");
    const { value: name } = useField("name");
    const { value: lastname } = useField("lastname");
    const { value: national_code } = useField("national_code");
    const { value: birthday } = useField("birthday");
    const { value: rules } = useField("rules", undefined, {
      type: "checkbox",
      checkedValue: true,
      uncheckedValue: false,
    });
    const { user } = useAuth();
    const { call, status, error } = useApi();

    watch(
      () => user.value,
      (value) => {
        if (value) {
          const {
            item: { mobile, name, lastname, national_code, birthday },
          } = value;

          setFieldValue("mobile", mobile as string);
          if (name !== "کاربر" && name) {
            setFieldValue("name", name);
          }
          if (lastname !== "جدید" && lastname) {
            setFieldValue("lastname", lastname);
          }
          national_code && setFieldValue("national_code", national_code);
          birthday && setFieldValue("birthday", birthday);
        }
      },
      {
        immediate: true,
      }
    );

    const onSubmit = handleSubmit(async (values) => {
      if (status.value === Status.LOADING) return;

      const data = {
        national_code: values.national_code,
        name: values.name,
        lastname: values.lastname,
        birthday: values.birthday,
      };

      await call({
        url: "/user/activation",
        method: "POST",
        data,
      });

      if (status.value === Status.SUCCESS) {
        emit("submit");
      }

      if (status.value === Status.ERROR) {
        const { errors } = error.value as unknown as ErrorType;
        emit("failed", errors);
      }
    });

    return {
      meta,
      errors,
      status,
      onSubmit,
      mobile,
      name,
      lastname,
      national_code,
      birthday,
      rules,
      digitsFaToEn,
    };
  },
});
