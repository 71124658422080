import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_alert = _resolveComponent("alert")!
  const _component_account_activation_form = _resolveComponent("account-activation-form")!
  const _component_modal = _resolveComponent("modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.activationSuccess)
      ? (_openBlock(), _createBlock(_component_alert, {
          key: 0,
          onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.activationSuccess = false)),
          variant: "success",
          title: "با موفقیت انجام شد!"
        }))
      : _createCommentVNode("", true),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activationErrors, (error, index) => {
      return (_openBlock(), _createBlock(_component_alert, {
        onClose: ($event: any) => (delete _ctx.activationErrors[index]),
        key: index,
        variant: "danger",
        title: error[0]
      }, null, 8, ["onClose", "title"]))
    }), 128)),
    _createVNode(_component_modal, {
      "model-value": _ctx.modelValue,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
    }, {
      header: _withCtx(() => [
        _createTextVNode("فعالسازی حساب")
      ]),
      default: _withCtx(() => [
        _createVNode(_component_account_activation_form, {
          onSubmit: _ctx.onActivationFormSubmit,
          onFailed: _cache[1] || (_cache[1] = ($event: any) => (_ctx.activationErrors = $event))
        }, null, 8, ["onSubmit"])
      ]),
      _: 1
    }, 8, ["model-value"])
  ], 64))
}