
import { defineComponent } from "vue";
import VideoHelpLink from "@/components/Ui/VideoHelpLink.vue";
import useConfig from "@/composables/useConfig";

export default defineComponent({
  name: "Help",
  components: {
    VideoHelpLink,
  },
  emits: ["close"],
  setup() {
    const { account_limitation } = useConfig();

    return {
      account_limitation,
    };
  },
});
