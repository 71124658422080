
import {
  defineComponent,
  onMounted,
  ref,
  watch,
  getCurrentInstance,
} from "vue";
import { Modal } from "bootstrap";
import { onBeforeRouteLeave } from "vue-router";

export default defineComponent({
  name: "Modal",
  props: {
    modelValue: Boolean,
    noHeader: Boolean,
    noPadding: Boolean,
  },
  emits: ["update:modelValue", "hide", "hidden", "show", "shown"],
  setup(props, { emit }) {
    const instance = getCurrentInstance();
    const modalRef = ref<HTMLDivElement>();
    const html = document.querySelector("html") as HTMLElement;

    onMounted(() => {
      watch(
        () => props.modelValue,
        (value) => {
          if (modalRef.value) {
            const modal = Modal.getOrCreateInstance(
              modalRef.value as HTMLElement
            );

            if (value) {
              modal.show();
              html.style.overflowY = "hidden";
            } else {
              modal.hide();
              html.style.overflowY = "auto";
            }

            emit("update:modelValue", value);
          }
        },
        {
          immediate: true,
        }
      );

      if (modalRef.value) {
        modalRef.value.addEventListener("hidden.bs.modal", () => {
          emit("update:modelValue", false);
          emit("hidden");
          html.style.overflowY = "auto";
        });

        modalRef.value.addEventListener("shown.bs.modal", () => {
          emit("shown");
        });

        modalRef.value.addEventListener("show.bs.modal", () => {
          emit("show");
        });

        modalRef.value.addEventListener("hide.bs.modal", () => {
          emit("hide");
        });
      }
    });

    onBeforeRouteLeave(() => {
      if (modalRef.value) {
        const modal = Modal.getOrCreateInstance(modalRef.value as HTMLElement);
        modal.hide();
        html.style.overflowY = "auto";
      }
    });

    return {
      modalRef,
      id: instance?.uid,
    };
  },
});
