import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-604c0dca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progress-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "progress-bar__inner",
      style: _normalizeStyle({ width: `${_ctx.percentage}%` })
    }, _toDisplayString(_ctx.label), 5)
  ]))
}