
import { defineComponent, ref } from "vue";
import Alert from "@/components/Ui/Alert.vue";
import Modal from "@/components/Ui/Modal.vue";
import AccountActivationForm from "@/components/Page/Account/Profile/AccountActivationForm.vue";
import { ErrorType } from "@/types/base";
import useAuth from "@/composables/useAuth";

export default defineComponent({
  name: "AccountActivationModal",
  components: {
    Alert,
    Modal,
    AccountActivationForm,
  },
  props: {
    modelValue: Boolean,
  },
  setup(_, { emit }) {
    const activationSuccess = ref<boolean>(false);
    const activationErrors = ref<ErrorType["errors"]>();
    const { fetchUserDetails } = useAuth();

    const onActivationFormSubmit = async () => {
      emit("update:modelValue", false);
      activationSuccess.value = true;

      await fetchUserDetails();
    };

    return {
      activationSuccess,
      activationErrors,
      onActivationFormSubmit,
    };
  },
});
