
import { defineComponent } from "vue";

export default defineComponent({
  name: "Windows10Loading",
  props: {
    label: {
      type: String,
      required: false,
    },
  },
});
