
import { defineComponent, ref } from "vue";
import ResolvingLimitationStepOne from "./ResolvingLimitationStepOne.vue";
import ResolvingLimitationStepTwo, {
  source,
} from "./ResolvingLimitationStepTwo.vue";

export default defineComponent({
  name: "ResolvingLimitation",
  components: {
    ResolvingLimitationStepOne,
    ResolvingLimitationStepTwo,
  },
  emits: ["done", "help"],
  setup() {
    const step = ref<number>(1);
    const file = ref();

    const back = () => {
      source.cancel();
      step.value = 1;
    };

    return {
      step,
      file,
      back,
    };
  },
});
